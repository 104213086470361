import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 581.000000 582.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,582.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M193 5787 c-39 -58 -72 -133 -88 -198 -13 -56 -15 -387 -15 -2714 0
-2574 1 -2652 19 -2715 10 -36 29 -86 40 -112 l22 -48 99 0 99 0 3 158 3 157
108 3 107 3 0 -28 0 -28 -77 0 -78 0 -3 -37 -3 -38 75 0 c74 0 76 -1 76 -25 0
-24 -2 -25 -75 -25 l-75 0 0 -45 0 -45 85 0 c47 0 84 -3 84 -7 -10 -62 20 -53
64 18 l37 60 -28 42 c-46 68 -46 67 -13 67 23 0 34 -7 48 -30 9 -16 20 -30 23
-30 3 0 14 14 23 30 14 23 25 30 48 30 31 0 35 -4 19 -23 -6 -7 -21 -29 -34
-49 l-25 -37 39 -60 c27 -42 46 -61 60 -61 19 0 20 6 20 116 0 114 0 115 23
112 12 -2 21 -9 20 -16 -4 -17 12 -15 27 3 22 27 75 19 104 -16 21 -24 26 -41
26 -84 0 -43 -5 -60 -26 -84 l-26 -31 73 0 73 0 -26 28 c-35 36 -44 88 -24
137 9 21 24 44 34 51 21 16 76 18 103 4 24 -13 49 -61 49 -95 0 -24 -2 -25
-70 -25 -54 0 -70 -3 -70 -14 0 -38 61 -61 78 -30 10 18 43 16 49 -3 2 -6 -5
-21 -16 -32 l-21 -21 50 0 50 0 0 115 0 115 25 0 c14 0 25 -5 25 -12 0 -9 3
-9 12 0 14 14 68 16 68 3 0 -25 -14 -41 -36 -41 -33 0 -44 -28 -44 -109 l0
-71 55 0 55 0 0 115 0 115 25 0 25 0 0 -115 0 -115 51 0 50 0 -25 33 c-30 39
-35 101 -11 147 19 36 43 50 87 50 52 -1 82 -33 89 -95 l4 -30 -67 -3 c-57 -2
-68 -6 -68 -20 0 -38 46 -56 70 -27 14 16 46 20 54 6 3 -5 -4 -20 -15 -35
l-20 -26 49 0 49 0 0 108 c1 59 2 110 2 115 2 13 40 7 49 -9 8 -14 10 -14 21
0 26 31 107 13 112 -25 1 -5 3 -13 4 -19 1 -5 3 -46 4 -90 l1 -80 51 0 51 0
-26 31 c-43 51 -33 156 18 191 19 13 91 9 113 -7 11 -8 23 -24 26 -35 4 -17 0
-20 -23 -20 -16 0 -30 7 -34 15 -3 8 -14 15 -25 15 -50 0 -71 -106 -27 -135
26 -18 53 -11 61 14 4 13 13 17 30 13 32 -6 32 -26 0 -57 l-24 -25 69 0 69 0
-24 26 c-31 33 -41 85 -26 135 14 47 44 69 93 69 52 0 93 -48 97 -112 1 -16
-9 -18 -69 -18 -61 0 -70 -2 -70 -18 0 -38 61 -60 74 -27 6 17 24 19 46 5 12
-7 11 -13 -10 -34 l-23 -26 128 0 128 0 -24 26 c-20 22 -24 36 -24 89 0 53 4
67 24 89 26 28 67 34 102 16 18 -10 19 -7 19 40 0 27 5 51 10 51 47 6 45 14
44 -154 l0 -157 39 0 c38 0 39 1 24 18 -40 44 -9 99 66 117 31 8 42 16 42 30
0 26 -40 32 -64 11 -18 -16 -51 -13 -51 5 0 26 42 49 88 49 68 0 84 -20 87
-107 2 -38 3 -80 4 -95 1 -26 4 -28 43 -28 33 0 39 3 30 12 -7 7 -12 43 -12
90 0 63 -3 78 -15 78 -9 0 -15 9 -15 25 0 16 6 25 15 25 9 0 15 9 15 24 0 14
10 30 25 40 l25 16 0 -40 c0 -33 3 -40 20 -40 15 0 20 -7 20 -25 0 -18 -5 -25
-20 -25 -18 0 -20 -7 -20 -65 0 -58 2 -65 20 -65 15 0 20 -7 20 -25 0 -21 5
-25 32 -25 29 0 30 1 15 18 -23 25 -21 68 2 91 10 10 38 22 61 26 43 7 63 31
39 46 -22 14 -51 11 -63 -7 -13 -17 -46 -10 -46 11 0 41 102 62 148 29 20 -14
22 -24 22 -99 0 -46 3 -90 6 -99 5 -13 25 -16 112 -16 l106 0 -28 29 c-50 52
-38 163 21 190 42 19 91 13 118 -14 31 -31 31 -41 0 -47 -17 -4 -29 1 -38 15
-17 26 -62 17 -71 -15 -11 -43 -6 -85 12 -101 24 -22 35 -21 57 3 21 23 65 27
65 5 0 -8 -12 -26 -27 -40 l-28 -25 79 0 79 0 -26 21 c-15 11 -31 38 -37 60
-22 80 26 149 102 149 32 0 47 -7 75 -35 33 -33 35 -39 31 -86 -4 -37 -12 -57
-33 -79 l-29 -30 52 0 51 0 3 113 c3 108 4 112 26 115 15 2 22 -2 22 -13 0
-15 2 -15 22 -1 28 20 67 20 94 2 17 -12 22 -12 39 0 21 15 63 18 83 6 20 -13
32 -70 32 -149 l0 -73 30 0 30 0 0 90 c0 73 -3 90 -15 90 -9 0 -15 9 -15 25 0
15 6 25 14 25 8 0 17 15 20 35 8 39 30 55 75 55 24 0 31 -4 31 -20 0 -13 -7
-20 -20 -20 -20 0 -44 -32 -33 -44 3 -3 15 -6 25 -6 13 0 18 -7 18 -25 0 -19
-5 -25 -22 -25 -22 0 -23 -2 -20 -90 l3 -90 62 0 61 0 -30 31 c-25 26 -29 38
-29 85 0 50 3 59 33 85 62 55 149 30 173 -50 14 -48 -1 -102 -38 -131 l-26
-20 57 0 56 0 1 78 c1 42 1 94 0 114 -3 34 0 38 22 38 14 0 27 -6 30 -12 4
-10 7 -10 18 0 7 6 32 12 56 12 37 0 43 3 43 21 0 20 25 48 43 49 4 0 7 -16 7
-35 0 -28 4 -35 20 -35 15 0 20 -7 20 -25 0 -18 -5 -25 -20 -25 -18 0 -20 -7
-20 -60 0 -49 3 -61 20 -70 11 -6 20 -20 20 -30 0 -13 7 -20 20 -20 16 0 20 7
20 30 0 27 3 30 30 30 27 0 30 -3 30 -30 0 -27 3 -30 30 -30 27 0 30 3 30 30
0 25 4 30 25 30 21 0 25 -5 25 -30 0 -27 3 -30 30 -30 27 0 30 3 30 30 0 27 3
30 30 30 27 0 30 -3 30 -30 l0 -30 90 0 89 0 21 48 c12 26 30 78 41 117 18 67
19 114 18 1435 0 1358 0 1365 -19 1293 -39 -150 -114 -294 -215 -416 -48 -58
-108 -111 -228 -202 l-27 -20 15 -75 c17 -87 19 -160 5 -160 -5 0 -10 5 -10
11 0 8 -4 8 -14 0 -11 -10 -20 -10 -37 -1 -16 9 -23 9 -28 1 -7 -10 -6 32 2
152 2 22 -2 37 -9 37 -6 0 -16 -41 -23 -97 -24 -187 -76 -404 -121 -508 -5
-11 -22 -52 -39 -91 -76 -177 -182 -328 -322 -458 -171 -158 -389 -252 -704
-303 -84 -14 -218 -17 -832 -20 l-733 -4 0 1670 0 1671 678 0 c698 0 837 -6
1030 -40 l113 -20 72 34 c158 74 297 101 486 93 268 -11 477 -103 667 -292
141 -139 224 -283 274 -475 l19 -75 -2 1135 c-2 1065 -3 1138 -20 1185 -23 63
-35 88 -70 148 l-29 47 -2701 0 -2702 0 -22 -33z m2679 -20 c13 -14 21 -17 38
-17 18 0 50 -64 50 -100 0 -48 -22 -107 -50 -133 l-23 -22 6 23 c8 34 -2 52
-29 52 -13 0 -24 4 -24 8 0 5 -12 15 -27 22 -45 22 -62 41 -56 58 4 10 2 13
-5 8 -8 -4 -12 -1 -12 10 0 15 -3 15 -23 -4 -13 -12 -28 -22 -35 -22 -6 0 -16
-8 -21 -17 -9 -15 -10 -13 -11 12 0 39 32 90 72 115 39 23 129 27 150 7z m491
-140 c9 -13 23 -18 47 -15 21 2 41 -3 51 -12 9 -8 51 -34 93 -58 67 -37 76
-45 65 -59 -11 -12 -9 -18 8 -31 11 -10 28 -31 37 -49 l16 -32 -38 13 c-29 10
-39 20 -47 46 -7 22 -28 46 -62 73 l-52 39 -53 -13 c-81 -19 -83 -25 -38 -96
22 -34 40 -65 40 -68 0 -3 -12 -5 -27 -5 -27 0 -27 0 17 -46 l45 -46 -50 31
c-27 17 -55 29 -62 27 -8 -4 -13 13 -15 56 -2 37 -8 63 -15 65 -9 3 -12 -7
-10 -34 l2 -38 -47 -3 c-47 -3 -48 -3 -48 27 0 35 0 35 -69 20 -46 -10 -49
-12 -41 -33 8 -22 7 -22 -51 -18 -49 4 -59 2 -59 -11 0 -9 5 -19 11 -23 8 -4
4 -13 -11 -24 l-23 -18 33 -7 c22 -5 35 -3 40 5 4 7 13 9 19 5 22 -13 -10 -35
-51 -35 -52 0 -76 -17 -233 -171 -124 -120 -221 -189 -245 -174 -26 16 14 135
45 135 9 0 12 6 8 16 -5 14 -2 16 20 10 25 -6 27 -4 27 22 0 19 18 52 56 100
48 62 59 71 80 66 17 -4 24 -2 24 8 0 8 20 34 45 58 35 35 48 42 54 31 7 -11
11 -9 20 7 6 11 31 35 56 53 52 37 73 77 76 142 4 76 25 86 176 84 105 -2 124
-5 136 -20z m-620 -57 c14 -11 30 -20 36 -20 25 0 -19 -59 -45 -60 -11 0 -44
62 -44 85 0 21 23 19 53 -5z m-2043 -1485 l0 -1475 825 0 825 0 0 -195 0 -195
-1045 0 -1045 0 0 1670 0 1670 220 0 220 0 0 -1475z m145 1110 c105 -273 198
-520 213 -560 4 -11 5 -81 2 -155 -3 -74 -7 -112 -9 -85 -3 49 -3 49 -12 18
-11 -36 -23 -41 -46 -17 -9 8 -22 13 -30 10 -8 -3 -44 -6 -79 -6 -57 0 -64 -2
-64 -20 0 -22 -15 -27 -23 -7 -4 9 -8 8 -16 -3 -6 -8 -11 -10 -12 -5 -1 6 -5
-6 -9 -25 -5 -24 -7 -10 -5 45 1 44 1 63 -2 43 -2 -21 -8 -38 -14 -38 -5 0 -9
181 -10 465 -2 657 -2 632 5 624 2 -2 52 -130 111 -284z m2305 139 c0 -2 -9
-4 -21 -4 -11 0 -18 4 -14 10 5 8 35 3 35 -6z m405 -99 c49 -46 87 -87 83 -90
-10 -10 -149 106 -141 118 3 6 -7 21 -23 34 -16 12 -24 23 -19 22 6 0 50 -38
100 -84z m-1781 -371 c9 -3 13 -11 9 -17 -3 -5 -2 -7 4 -4 14 9 51 -20 69 -55
25 -49 7 -144 -34 -181 -20 -18 -20 -18 -19 14 2 28 -2 33 -30 41 -20 5 -33
15 -33 25 0 12 -3 13 -14 3 -12 -9 -22 -5 -50 20 -20 18 -32 38 -29 46 7 19
-11 18 -27 -1 -7 -8 -18 -15 -26 -15 -7 0 -18 -8 -23 -17 -8 -15 -10 -11 -11
18 0 42 36 101 64 105 10 1 23 7 29 13 12 12 95 15 121 5z m1301 -90 c-15 -25
-25 -48 -22 -50 2 -2 32 -9 67 -16 58 -10 63 -13 78 -47 16 -37 18 -156 4
-233 l-7 -38 -80 91 c-73 83 -82 97 -98 163 l-18 73 48 51 c26 28 50 51 51 51
2 1 -8 -20 -23 -45z m-790 -24 c4 -6 -5 -10 -20 -10 -15 0 -24 4 -20 10 3 6
12 10 20 10 8 0 17 -4 20 -10z m95 -25 c0 -3 -24 -30 -54 -60 -38 -39 -62 -55
-80 -55 -14 0 -26 -5 -26 -11 0 -5 -5 -7 -10 -4 -6 4 -7 11 -4 17 4 6 -2 17
-12 25 -17 13 -13 13 35 7 50 -6 53 -6 43 11 -7 14 -5 25 11 47 16 22 29 28
59 28 21 0 38 -2 38 -5z m-713 -74 l32 -25 -21 -23 c-12 -13 -28 -23 -36 -23
-9 0 -12 3 -9 6 3 4 -3 18 -13 32 -20 25 -26 53 -14 65 8 7 17 3 61 -32z m483
19 c-16 -12 -28 -16 -35 -9 -12 12 11 29 40 29 18 0 18 -2 -5 -20z m-364 -381
c-14 -11 -26 -27 -26 -35 0 -19 5 -18 47 11 20 13 38 23 40 21 2 -2 -26 -63
-62 -137 -56 -114 -63 -134 -48 -137 10 -2 43 -12 73 -22 30 -10 78 -24 105
-31 61 -16 150 -98 140 -130 -4 -11 -2 -19 5 -19 8 0 10 -13 5 -40 -5 -27 -3
-46 5 -55 10 -12 9 -15 -8 -15 -11 0 -39 7 -62 15 -23 8 -45 15 -47 15 -3 0 0
-7 7 -15 7 -8 21 -15 31 -15 10 0 21 -4 24 -10 3 -5 1 -10 -4 -10 -6 0 -10 -5
-9 -11 4 -16 -27 -33 -47 -25 -12 4 -15 13 -11 30 13 51 -170 146 -282 146
-40 0 -50 -5 -82 -39 l-38 -40 -6 31 c-10 50 -7 76 10 82 14 5 14 7 -1 22 -38
39 -57 95 -53 160 4 68 12 84 30 66 7 -7 18 -12 26 -12 12 0 13 11 8 54 l-6
54 58 22 c94 36 122 52 122 72 0 15 8 18 41 18 l42 0 -27 -21z m2072 -166
c-15 -2 -42 -2 -60 0 -18 2 -6 4 27 4 33 0 48 -2 33 -4z m-2641 -200 c-3 -10
-5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m50 41 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m787 -306 c3 -13 1 -27 -4 -32 -7 -7 -10 -4
-10 6 0 9 -3 23 -6 32 -3 9 -2 16 4 16 5 0 12 -10 16 -22z m-787 -714 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1087 -760 c3 -9 6 -27 6 -40
l0 -25 -944 3 c-519 2 -942 0 -940 -3 4 -8 -29 -6 -36 1 -3 3 -30 18 -60 34
-30 16 -59 33 -64 37 -6 5 440 9 1011 9 922 0 1021 -2 1027 -16z m-784 -1894
c0 -25 -4 -30 -25 -30 -34 0 -36 46 -2 53 12 2 23 5 25 6 1 0 2 -12 2 -29z"/>
<path d="M2717 5736 c-10 -8 -14 -16 -10 -19 5 -3 14 3 21 14 14 22 13 23 -11
5z"/>
<path d="M1638 4835 c-15 -13 -24 -26 -21 -29 3 -3 15 6 25 20 11 14 23 23 26
20 2 -3 0 -12 -6 -19 -9 -10 -9 -14 1 -14 14 0 21 34 9 41 -4 2 -19 -6 -34
-19z"/>
<path d="M4815 4030 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M4423 3999 c-38 -24 -43 -53 -12 -69 13 -7 18 -17 15 -27 -4 -10 -2
-13 8 -10 10 4 13 1 9 -9 -3 -7 0 -14 6 -14 6 0 11 -9 11 -20 0 -17 -4 -18
-25 -10 -27 10 -28 9 -25 -32 1 -22 -3 -28 -18 -28 -11 0 -26 7 -33 16 -7 9
-25 21 -39 26 -20 8 -17 23 5 18 10 -2 32 25 39 48 6 18 2 22 -21 28 -56 12
-63 16 -57 26 4 7 2 8 -4 4 -7 -4 -12 -19 -12 -33 0 -21 2 -23 15 -13 21 17
38 4 25 -21 -6 -10 -14 -18 -18 -18 -24 4 -33 -3 -27 -20 4 -12 0 -21 -12 -25
-10 -4 -19 -2 -21 3 -6 18 -22 13 -63 -20 -32 -25 -38 -36 -34 -57 2 -15 14
-35 26 -45 13 -10 19 -24 16 -36 -6 -23 12 -53 19 -34 3 7 16 28 30 48 13 20
24 43 24 51 0 8 7 28 15 44 27 52 83 33 76 -24 -3 -19 -2 -19 23 -3 32 21 38
21 35 0 -4 -43 2 -60 26 -71 14 -6 25 -8 25 -3 0 5 -7 11 -15 15 -22 8 -21 86
1 86 14 0 15 2 3 17 -9 10 -9 14 -2 10 7 -5 16 1 23 13 6 11 15 20 21 20 6 0
7 -4 4 -10 -8 -13 1 -13 26 0 10 6 19 19 19 28 0 10 4 22 9 28 4 5 6 32 2 59
-6 49 -7 50 -38 49 -25 0 -33 4 -33 18 0 23 -7 22 -47 -3z m57 -104 c-7 -9
-15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z"/>
<path d="M4996 4001 c-3 -5 3 -11 14 -14 13 -3 20 0 20 9 0 15 -26 19 -34 5z"/>
<path d="M4355 3950 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4216 3914 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z"/>
<path d="M5116 3874 c-8 -21 -8 -39 1 -30 8 7 15 46 9 46 -2 0 -7 -7 -10 -16z"/>
<path d="M4705 3840 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4510 3810 c-11 -7 -10 -11 4 -25 9 -10 15 -23 13 -29 -3 -6 8 -12
26 -13 l30 -2 -22 22 c-11 13 -21 28 -21 35 0 16 -14 22 -30 12z"/>
<path d="M5210 3809 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4623 3775 c-3 -9 -3 -19 1 -22 3 -4 6 1 6 10 0 8 6 18 13 20 9 4 9
6 -1 6 -7 1 -15 -6 -19 -14z"/>
<path d="M2840 2409 l0 -1281 488 5 c549 5 619 12 792 75 64 24 176 86 226
127 157 129 291 406 337 696 l14 86 -111 16 c-476 67 -841 465 -873 951 -4 63
-9 93 -14 81 -6 -15 -8 -10 -9 20 -1 22 -4 32 -7 23 -3 -13 -16 -18 -46 -20
l-42 -1 40 -7 40 -8 -45 4 c-25 2 -91 10 -147 17 -57 7 -103 9 -103 4 0 -4
-23 -7 -52 -6 -34 2 -53 -1 -56 -10 -3 -9 -7 -9 -18 0 -8 6 -14 8 -14 5 0 -16
-70 67 -125 147 -33 49 -97 141 -142 205 -45 64 -83 121 -83 126 0 8 921 4
949 -5 7 -2 15 1 18 7 7 19 -112 24 -574 24 l-443 0 0 -1281z m161 1049 c126
-188 347 -516 406 -602 27 -40 33 -58 33 -102 0 -39 -4 -56 -15 -60 -21 -8
-19 -34 3 -35 16 -1 16 -2 0 -6 -10 -2 -18 -9 -18 -14 0 -6 -7 -3 -16 5 -9 9
-25 14 -37 11 -12 -3 -75 -7 -139 -8 -86 -1 -118 -5 -118 -14 0 -16 -17 -17
-22 -2 -2 7 -39 12 -100 13 l-96 1 -6 29 c-7 32 -8 966 -2 966 2 0 59 -82 127
-182z m257 -300 c15 -15 15 -28 1 -28 -5 0 -8 4 -4 9 3 6 -3 14 -12 20 -10 6
-13 11 -7 11 5 0 15 -5 22 -12z"/>
<path d="M4440 3646 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M4452 3600 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4363 3591 c-50 -6 -85 -21 -49 -21 12 0 16 -5 11 -19 -6 -21 15 -34
42 -24 27 11 5 -15 -28 -32 -27 -14 -33 -13 -30 8 2 10 -6 9 -39 -8 -17 -9
-30 -22 -30 -30 0 -8 -6 -15 -12 -15 -19 -1 -68 -47 -68 -63 0 -8 -18 -31 -40
-51 -35 -32 -40 -41 -40 -81 0 -54 -18 -60 -22 -7 l-3 37 -63 2 c-71 2 -74 -1
-85 -85 -4 -30 -1 -47 10 -57 19 -19 43 -19 43 0 0 8 9 15 20 15 23 0 25 -12
9 -43 -10 -18 -8 -24 15 -42 21 -17 25 -27 20 -51 -4 -21 -1 -34 13 -46 22
-20 73 -34 73 -20 0 14 38 22 44 8 3 -8 7 -8 13 1 7 10 18 8 48 -7 22 -11 44
-20 51 -20 6 0 25 -16 43 -35 17 -19 47 -41 65 -49 19 -8 42 -27 52 -41 17
-26 17 -28 1 -40 -25 -18 -21 -28 11 -21 26 6 26 6 8 -9 -18 -13 -18 -15 -3
-15 10 0 26 5 37 12 15 10 22 9 38 -5 10 -9 34 -20 53 -23 46 -9 89 -41 89
-66 0 -11 -13 -36 -30 -55 -16 -18 -30 -45 -30 -58 0 -39 -21 -75 -43 -75 -46
0 -85 -23 -91 -52 -7 -34 -23 -53 -54 -62 -12 -4 -22 -11 -22 -15 0 -5 -13
-11 -30 -14 -42 -9 -37 -21 18 -45 238 -102 545 -89 779 32 138 72 132 64 134
181 1 60 7 113 15 134 18 44 18 101 -1 128 -11 16 -14 34 -9 62 6 39 5 41 -19
41 -14 0 -29 4 -32 10 -4 6 -19 5 -41 -5 -19 -8 -60 -17 -92 -20 -49 -6 -61
-4 -84 15 -16 12 -28 28 -28 35 0 7 -12 28 -27 46 -25 29 -27 37 -24 113 2 57
7 86 18 99 25 27 9 30 -17 2 -15 -16 -24 -41 -28 -75 -2 -27 -8 -50 -12 -50
-4 0 -11 -12 -15 -27 -4 -16 -10 -22 -12 -15 -3 6 -19 12 -36 12 -37 0 -89 15
-139 41 -20 10 -44 19 -52 19 -9 0 -16 5 -16 10 0 6 -3 9 -7 9 -24 -4 -53 2
-49 10 3 5 -1 14 -9 21 -8 7 -12 17 -8 23 3 5 1 7 -4 4 -13 -8 -75 62 -67 74
3 5 0 9 -6 9 -6 0 -8 5 -5 10 3 6 0 10 -9 10 -8 0 -17 -6 -20 -12 -3 -7 -3 -1
0 14 7 34 -3 68 -22 68 -9 0 -14 11 -14 34 0 19 -4 38 -10 41 -5 3 -10 15 -10
26 0 11 -4 17 -10 14 -5 -3 -10 1 -10 9 0 9 -5 16 -12 16 -6 0 -8 3 -4 8 4 4
13 7 19 7 7 0 12 9 12 20 0 11 7 20 15 21 24 1 60 15 60 24 0 9 -50 8 -65 -2
-6 -3 -11 6 -12 21 -1 20 5 32 25 44 29 17 26 18 -55 8z m-248 -448 c36 -25
38 -28 18 -31 -13 -2 -32 6 -45 17 -12 12 -27 21 -34 21 -7 0 -16 5 -19 10
-13 21 42 9 80 -17z m75 -53 c12 -8 9 -10 -12 -10 -16 0 -28 5 -28 10 0 13 20
13 40 0z"/>
<path d="M5478 3499 c18 -12 36 -18 40 -14 10 10 -28 35 -52 35 -17 0 -15 -4
12 -21z"/>
<path d="M5440 3490 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5411 3434 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5420 3390 c0 -6 10 -15 23 -20 12 -5 18 -9 12 -10 -5 -1 -2 -5 9 -9
17 -7 17 -8 2 -24 -16 -16 -15 -17 18 -17 29 0 34 3 29 17 -5 11 -3 14 6 8 10
-6 11 -3 2 14 -7 12 -9 26 -6 31 9 14 -12 21 -37 11 -15 -5 -31 -5 -41 0 -11
7 -17 6 -17 -1z"/>
<path d="M5060 3320 c0 -11 2 -20 4 -20 2 0 6 9 9 20 3 11 1 20 -4 20 -5 0 -9
-9 -9 -20z"/>
<path d="M5430 3330 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M5360 3312 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
<path d="M3816 3276 c5 -19 3 -27 -4 -22 -14 9 -20 -107 -9 -217 13 -141 73
-306 157 -430 60 -90 248 -262 266 -245 3 3 -2 40 -10 82 -9 41 -16 84 -16 94
0 11 -16 33 -36 48 -44 35 -104 152 -104 204 0 19 7 48 15 64 16 30 19 65 7
81 -4 6 -20 15 -37 20 -20 7 -36 23 -48 47 -10 20 -37 53 -60 73 -88 75 -94
84 -100 141 -3 29 -10 61 -16 71 -9 15 -10 13 -5 -11z"/>
<path d="M5012 3240 c-8 -14 -8 -21 -1 -26 10 -6 28 12 29 28 0 16 -17 15 -28
-2z"/>
<path d="M4986 3194 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z"/>
<path d="M5580 3151 c0 -5 9 -19 20 -32 22 -28 28 -6 6 24 -14 18 -26 22 -26
8z"/>
<path d="M5646 3074 c-11 -7 -16 -17 -13 -21 4 -4 15 1 24 10 22 21 14 30 -11
11z"/>
<path d="M5605 2837 c-17 -42 -29 -87 -22 -87 3 0 14 27 25 60 22 64 20 82 -3
27z"/>
<path d="M5021 198 c-1 -12 -9 -18 -24 -18 -38 0 -47 -19 -47 -102 l0 -78 67
0 c52 0 64 3 55 12 -7 7 -12 43 -12 90 0 61 -3 78 -14 78 -8 0 -17 8 -19 18
-4 16 -5 16 -6 0z"/>
<path d="M950 170 c-27 -27 -27 -83 0 -110 24 -24 30 -24 56 -6 14 11 19 26
19 61 0 35 -5 50 -19 61 -26 18 -32 18 -56 -6z"/>
<path d="M1188 179 c-10 -5 -18 -19 -18 -29 0 -17 7 -20 39 -20 47 0 55 9 35
38 -17 24 -30 27 -56 11z"/>
<path d="M1692 178 c-7 -7 -12 -20 -12 -30 0 -14 8 -18 40 -18 32 0 40 4 40
18 0 22 -19 42 -40 42 -9 0 -21 -5 -28 -12z"/>
<path d="M1938 179 c-15 -8 -18 -25 -18 -94 l0 -85 40 0 40 0 0 89 c0 72 -3
90 -16 95 -21 8 -26 7 -46 -5z"/>
<path d="M2402 178 c-7 -7 -12 -20 -12 -30 0 -14 8 -18 40 -18 32 0 40 4 40
18 0 22 -19 42 -40 42 -9 0 -21 -5 -28 -12z"/>
<path d="M2753 178 c-32 -42 -24 -113 14 -131 22 -10 28 -8 44 12 23 29 25 93
3 115 -19 19 -48 21 -61 4z"/>
<path d="M3970 183 c-43 -15 -55 -88 -20 -123 25 -25 42 -25 64 -1 38 42 3
140 -44 124z"/>
<path d="M4208 179 c-15 -8 -18 -25 -18 -94 l0 -85 35 0 36 0 -3 92 c-3 98
-11 111 -50 87z"/>
<path d="M4332 178 c-7 -7 -12 -42 -12 -95 l0 -83 35 0 36 0 -3 93 c-3 83 -5
92 -23 95 -11 1 -26 -3 -33 -10z"/>
<path d="M4712 174 c-17 -12 -22 -25 -22 -59 0 -34 5 -47 22 -59 29 -20 34
-20 58 4 29 29 27 94 -2 114 -12 9 -25 16 -28 16 -3 0 -16 -7 -28 -16z"/>
<path d="M3025 103 c-11 -3 -26 -12 -32 -21 -24 -30 27 -58 55 -30 13 13 17
59 5 57 -5 -1 -17 -3 -28 -6z"/>
<path d="M3384 100 c-28 -11 -36 -24 -28 -44 7 -19 45 -21 62 -4 16 16 15 58
0 57 -7 0 -23 -4 -34 -9z"/>
<path d="M708 42 c-24 -41 -24 -42 22 -42 46 0 49 8 18 45 l-23 26 -17 -29z"/>
<path d="M930 10 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"/>
<path d="M2805 10 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8 0
-18 -4 -21 -10z"/>
<path d="M3045 10 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M3410 10 c-12 -8 -10 -10 8 -10 13 0 20 4 17 10 -7 12 -6 12 -25 0z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
